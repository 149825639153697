<template>
  <div
    class="px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center"
  >
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
      <div class="text-center mb-5"></div>

      <div>
        <div class="p-field mb-4">
          <label for="client" class="block text-900 font-medium mb-2"
            >Choose a Client:</label
          >
          <Dropdown
            type="email"
            scroll-height="235px"
            class="p-component w-full"
            :options="clients"
            optionLabel="label"
            optionValue="id"
            v-model="client_id"
          />
        </div>

        <Button
          label="Switch Client"
          class="p-button p-component w-full p-3"
          @click.prevent="switchClient"
          :loading="loading"
        ></Button>
      </div>
    </div>
  </div>
</template>
<script>
import Client from "@/models/Client";
import axios from "axios";
import { useAuthStore } from "@/stores/auth";
import { useAppStore } from "@/stores/app";
export default {
  setup() {
    const authStore = useAuthStore();
    const appStore = useAppStore();
    return { appStore, authStore };
  },
  data() {
    return {
      client_id: null,
      clients: [],
      loading: false,
    };
  },
  methods: {
    async switchClient() {
      await axios
        .post("/clients/switch", { client_id: this.client_id })
        .then(async (res) => {
          await this.appStore.setCurrentCampaign(null);
          await this.authStore.me();
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: res.data.message,
            life: 1500,
          });
          this.$router.push({ name: "dashboard" });
        });
    },
  },
  async mounted() {
    await axios
      .get("/validate/user/can-switch-clients")
      .then(
        await Client.get().then((res) => {
          this.clients = res.data.map((x) => {
            return {
              label: x.name,
              id: x.id,
            };
          });
        })
      )
      .catch((err) => {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Access Denied: " + err.message,
          life: 1500,
        });
        this.$router.push("/");
      });
  },
};
</script>

<style scoped>
#signInImage {
  background-image: url("~@/assets/signin.jpg");
}
</style>
